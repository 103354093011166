export const HOME_PAGE_ROUTE = '/';
export const LOGIN_PAGE_ROUTE = '/login';
export const SIGNUP_PAGE_ROUTE = '/signup';
export const MAGIC_PAGE_ROUTE = '/magic';
export const WORKSPACE_PAGE_ROUTE = '/workspace';
export const FORGOT_PASSWORD_PAGE_ROUTE = '/forgot';
export const ORG_PAGE_ROUTE = '/org';
export const TOS_PAGE_ROUTE = '/tos';
export const PRIVACY_PAGE_ROUTE = '/privacy';
export const QUERY_PARAM_REDIRECTED_FROM = 'redirectedFrom';
export const ACCOUNT_PAGE_ROUTE = '/account';
export const ACCOUNT_PREFERENCES_ROUTE = `${ACCOUNT_PAGE_ROUTE}/preferences`;
export const DASHBOARD_API_ROUTE = '/api/dashboard';
