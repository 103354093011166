import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import NavContentComponent from './side-nav';
import { Section } from './side-nav-section';
import { useProfile } from '@/contexts/profile';

interface ISidebarLayoutProps {
  children: React.ReactNode;
}

export default function OrgComponent({ children }: ISidebarLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const staticOrgSections: Section[] = [
    {
      name: 'Workspaces',
      items: [{ name: 'All Workspaces', href: '/workspace', initial: 'W' }],
    },
    {
      name: 'Account',
      items: [{ name: 'Preferences', href: '/account/preferences', initial: 'P' }],
    },
  ];

  const [sections, setSections] = useState<Section[]>(staticOrgSections);
  const { profile } = useProfile();

  useEffect(() => {
    // console.log('Profile changed', profile);
    if (profile) {
      const { org } = profile;
      if (Array.isArray(org)) {
        console.log('Orgs', org);
        const orgSectionName = 'Org Settings';
        const orgSection: Section = {
          name: orgSectionName,
          items: org.map((o) => ({
            name: o.name,
            href: `/org/${o.id}/general`,
            initial: o.name[0],
            sectionItemStartsWith: `/org/${o.id}`,
          })),
        };

        const staticOrgSectionsCopy = [...staticOrgSections]; //creates a new local copy
        staticOrgSectionsCopy.splice(1, 0, orgSection); //splice modifies the existing array.
        setSections(staticOrgSectionsCopy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <NavContentComponent sections={sections} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <NavContentComponent sections={sections} />
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">Dashboard</div>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
}
