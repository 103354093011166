/**
 * create a global context and custom nook to provide user profile and logout functionality.
 *
 */

import { LOGIN_PAGE_ROUTE } from '@/constants/routes';
import { Database } from '@/supabase/types.gen';
import { SupabaseClient, User, useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/router';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type ProfileContextProps = {
  logout: () => Promise<void>;
  setRefreshProfile: Dispatch<SetStateAction<boolean>>;
  profile: Profile;
};

const ProfileContext = createContext<ProfileContextProps | null>(null);

type ProviderProps = {
  children: ReactNode;
};

async function getProfile(supabase: SupabaseClient<Database>, user: User) {
  const { data: profile } = await supabase
    .from('profile')
    .select(
      `
    name,
    last_updated,
    email,
    org(id,name)
    `
    )
    .eq('id', user.id)
    .single();
  return profile;
}

export function getOrgsFromProfile(profile: Profile) {
  if (!profile) return null;
  if (!profile.org) return null;
  const orgs = profile.org as SingleOrg[];
  return orgs;
}

type Profile = Awaited<ReturnType<typeof getProfile>>;
type Org = NonNullable<Profile>['org'];
export type SingleOrg = Exclude<Org, Array<any> | null>;

const UserProfileProvider = ({ children }: ProviderProps) => {
  const router = useRouter();
  const supabase = useSupabaseClient<Database>();
  const user = useUser();
  const [profile, setProfile] = useState<Profile>(null);
  const [refreshProfile, setRefreshProfile] = useState(false);

  useEffect(() => {
    const getUserProfile = async () => {
      if (user) {
        console.log('Data being fetched');
        const pr = await getProfile(supabase, user);

        if (pr) {
          console.log('Profile from backend ', pr);
          setProfile(pr);
        }
      }
    };

    if (user && !profile) {
      console.log('getting user profile for the first time');
      getUserProfile();
    }

    if (refreshProfile) {
      console.log('Refreshing profile');
      getUserProfile();
      setRefreshProfile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refreshProfile]);

  useEffect(() => {
    //. This will sign out all the pages opened in browser when logout happens on one page.
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_OUT') {
        //if event is signed_out, redirect to login page
        router.push(LOGIN_PAGE_ROUTE);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  });

  const logout = async () => {
    await supabase.auth.signOut();
    setProfile(null);
    // router.push(LOGIN_PAGE_ROUTE);
  };

  const exposed = {
    profile,
    logout,
    setRefreshProfile,
  };

  return <ProfileContext.Provider value={exposed}>{children}</ProfileContext.Provider>;
};

export const useProfile = (): ProfileContextProps => {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error('useProfile must be used within a UserProfileProvider');
  }
  return context;
};

export default UserProfileProvider;
