import { useProfile } from '@/contexts/profile';
import FluxLogo from './flux-logo';
import NavSectionComponent, { Section } from './side-nav-section';

import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';

export interface INavContentProps {
  sections: Section[];
}

const NavContentComponent = ({ sections }: INavContentProps) => {
  const { logout } = useProfile();

  return (
    <>
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ">
        <div className="flex h-20 shrink-0 items-center">
          <FluxLogo />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            {sections.map((section) => (
              <NavSectionComponent section={section} key={section.name} />
            ))}

            <li className="-mx-6 mt-auto">
              <button
                onClick={() => logout()}
                className="flex w-full items-center gap-x-2 px-6 py-3 text-sm  text-gray-400 leading-6 font-semibold hover:text-gray-100 hover:bg-gray-800 "
              >
                <ArrowRightOnRectangleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                <span aria-hidden="true">Logout</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default NavContentComponent;
