/**
 * Write a component which returns this
 *
 */
import Image from 'next/image';
import logo from '@/public/images/flux_logo_light.svg';

const FluxLogo = () => {
  return <Image className="h-8 w-auto" src={logo} width={144} height={144} alt="logo" />;
};

export default FluxLogo;
