import Link from 'next/link';
import { useRouter } from 'next/router';

/**
 * 
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
 * @returns 
 */

export type SectionItem = {
  name: string;
  href: string;
  initial: string;
  sectionItemStartsWith?: string;
};

interface ISectionItemProps {
  item: SectionItem;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const SectionItemComponent = ({ item }: ISectionItemProps) => {
  const router = useRouter();
  const isActive =
    router.asPath === item.href ||
    (item.sectionItemStartsWith && router.asPath.startsWith(item.sectionItemStartsWith));

  return (
    <li>
      <Link
        href={item.href}
        className={classNames(
          isActive ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
        )}
      >
        <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
          {item.initial.toUpperCase()}
        </span>
        <span className="truncate">{item.name}</span>
      </Link>
    </li>
  );
};

export default SectionItemComponent;
