import '@/styles/globals.css';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { Session, SessionContextProvider } from '@supabase/auth-helpers-react';
import type { AppProps } from 'next/app';
import { useState } from 'react';
import { NextIntlProvider } from 'next-intl';
import messages from '@/messages/en.json';
import { useRouter } from 'next/router';
import { ACCOUNT_PAGE_ROUTE, ORG_PAGE_ROUTE, WORKSPACE_PAGE_ROUTE } from '@/constants/routes';
import { Toaster } from 'react-hot-toast';
import UserProfileProvider from '@/contexts/profile';
import OrgComponent from '@/components/side-nav/org';

export default function App({
  Component,
  pageProps,
}: AppProps<{
  initialSession: Session;
  messages: IntlMessages;
}>) {
  // Create a new supabase browser client on every first render.
  const [supabaseClient] = useState(() => createBrowserSupabaseClient());
  const router = useRouter();

  // console.log('I am in app.tsx ', router.pathname);

  // check if pathnames are in the array of routes that need the org nav bar
  const isExactlyWorkspaceRoute = router.pathname === WORKSPACE_PAGE_ROUTE;
  const isOrgOrAccountRoute = [ORG_PAGE_ROUTE, ACCOUNT_PAGE_ROUTE].some((route) => {
    return router.pathname.startsWith(route);
  });

  const shouldShowOrgNavBar = isExactlyWorkspaceRoute || isOrgOrAccountRoute;

  return (
    //check if loading the full strings and passing it to every page on every load will not hinder performance.
    <NextIntlProvider messages={messages}>
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <UserProfileProvider>
          {shouldShowOrgNavBar ? ( // check if isSideNavBarVisible is true
            <OrgComponent>
              <Component {...pageProps} />
              <Toaster position="top-right" />
            </OrgComponent>
          ) : (
            <>
              <Component {...pageProps} />
              <Toaster position="top-right" />
            </> // render only the Component
          )}
        </UserProfileProvider>
      </SessionContextProvider>
    </NextIntlProvider>
  );
}
