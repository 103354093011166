import SectionItemComponent, { SectionItem } from './section-item';

export type Section = {
  name: string;
  items: SectionItem[];
};

interface INavSectionProps {
  section: Section;
}

const NavSectionComponent = ({ section }: INavSectionProps) => {
  const { items, name } = section;
  return (
    <li>
      <div className="text-sm font-semibold leading-6 text-gray-400">{name}</div>
      <ul role="list" className="-mx-2 mt-2 space-y-1">
        {items.map((item) => (
          <SectionItemComponent item={item} key={item.name} />
        ))}
      </ul>
    </li>
  );
};

export default NavSectionComponent;
